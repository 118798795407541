import logo from "./logo.svg";
import "./App.css";
import { ReactCookieFirst } from "@cookiefirst/cookiefirst-react";
function App() {
  return (
    <ReactCookieFirst url="https://190.cookiefirst.dev/storage/sites/mushka.dev-68ff8b80-2f36-4067-bff5-ea8323455ef7/consent.js">
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>{window.location.host}</p>
          <p>
            Edit <code>src/App.js</code> and save to reload.
          </p>
          <a
            className="App-link"
            href="https://reactjs.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn React
          </a>
        </header>
      </div>
    </ReactCookieFirst>
  );
}
export default App;
